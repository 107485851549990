import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Coordinates } from '@parashift/shared/models';
import { validCoordinates } from '@parashift/shared/utils';

export interface CoordinatesPreview {
  coordinates: Coordinates;
  page_number: number;
}

@Injectable({
  providedIn: 'root'
})
export class DocumentTypeConfiguratorPreviewService {
  private _docTypeFieldPreview$ = new Subject<CoordinatesPreview>();
  docTypeFieldPreview$ = this._docTypeFieldPreview$.asObservable();

  setDocTypeFieldPreview(coordinates: Partial<Coordinates>, page_number = 0): void {
    if (validCoordinates(coordinates)) {
      this._docTypeFieldPreview$.next({ coordinates, page_number });
    } else {
      this._docTypeFieldPreview$.next(undefined);
    }
  }
}
